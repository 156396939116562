import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import HeroImage from '../images/IMG_1007.jpg';

const Archives = (props) => {
  const markdownList = props.data.md.edges;
  return (
    <Layout>
      <SEO title="Media" />
      <div
        className="wrapper-body"
        style={{ backgroundColor: '#fff' }}
        id="why-use-e1"
      >
        <section
          id="intro"
          style={{ height: '450px', backgroundImage: `url(${HeroImage})` }}
        >
          <div className="container">
            <div
              className="col-md-12"
              style={{ paddingTop: '120px', paddingBottom: '100px' }}
            >
              <h1>Media</h1>
            </div>
          </div>
        </section>

        <div className="padding-topbottom-50 main-text">
          <p className="padding-bottom-25">
            To request a media kit or to speak to an E1 Transit Spokesperson,
            please go to our <Link to="/contact">contact us</Link> page.
          </p>

          <hr />
          <p>Here are some links to our latest media articles:</p>

          <section id="one">
            {markdownList.map((item, key) => (
              <React.Fragment key={key}>
                <div className="padding-topbottom-15">
                  <h2>
                    {item.node.frontmatter.date} -{' '}
                    <Link to={item.node.frontmatter.slug}>
                      {item.node.frontmatter.title}
                    </Link>
                  </h2>
                </div>
              </React.Fragment>
            ))}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Archives;

export const pageQuery = graphql`
  query {
    md: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            slug
            date(formatString: "DD MMMM YYYY")
            title
          }
        }
      }
    }
  }
`;
